import styled from "styled-components";




// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--dark-grey);
  background-image: url(../assets/img/bring_me.gif);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Rubik";

  @media (max-width: 767px) {
    background-color: var(--dark-grey);
    background-image: url(../assets/img/main_bg.png);
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: "Rubik"; }

`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
  font-family: "Rubik";
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
  font-family: "Rubik";
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
  font-family: "Rubik";
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
  font-family: "Rubik";
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  font-family: "Rubik";
`;

export const Container2 = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  font-family: "Rubik";
`;
//font-size: 18px;
//font-weight: 500;
//line-height: 1.6;
export const TextTitle = styled.p`
  color: var(--black);
  font-size: 26px;
  line-height: 50px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-family: "Rubik";

  @media only screen and (max-width: 768px){
  
    color: var(--black);
    font-weight: 600;
    font-size: 18px;
    line-height: 100.52%;
    font-family: "Rubik";
  }
  
`;

export const TextTitle3 = styled.p`
  color: var(--black);
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Rubik";
  
  @media only screen and (max-width: 768px) {
  font-size: 16px;

  }
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 14px;
  line-height: 1.6;
  font-family: "Rubik";
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 12px;
  line-height: 1.6;
  font-family: "Rubik";
`;

export const TextDescription2 = styled.p`
  color: #F2BB49;
  text-shadow:
  3px 3px 0 #000,
-1px -1px 0 #000,  
 1px -1px 0 #000,
 -1px 1px 0 #000,
  1px 1px 0 #000;
  font-weight: 900;
  font-size: 32px;
  line-height: 166.52%;
  font-family: "Rubik";

`;

export const TextDescription3 = styled.p`
color: var(--white);
font-size: 20px;
line-height: 0px;
margin-top: 20px;
margin-bottom: 20px;
text-align: center;
font-weight: bold;
font-family: "Rubik";

`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

