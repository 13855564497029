import React, { useEffect, useState, useRef, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import "./styles/theme.css";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

// TODO: This is missing functionality for sub-menu here from SUI core examples.
// The "Publish To Web" item should contain a sub-menu.
//const InputField = styled.input``;

const Input = styled.input`

    font-size: 1.4rem;
    font-family: "Rubik";
    text-align: center;
    display: inline-block;
    height: 60px;
    box-sizing: border-box;
    font-weight: 900;
    outline: none;
    border: none;
    /* margin: 0; */
    background: papayawhip;
    border-radius: 3px;
    color: #000!important;
    transition: all 2s cubic-bezier(.075,.82,.165,1);
    color: #000;
    background-image: linear-gradient(
    45deg,#ed9419 50%,transparent 0);
    background-position: 100%;
    background-size: 400%;
    -webkit-appearance: none;
    appearance: none;
    margin-right: 10px;

`;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border-color: #212529;
  border-width: 4px;
  background-color: #F9C04B;
  padding: 10px;
  font-family: "Rubik";
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  width: 300px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0px 4px 16px 0px #00000;

  &:hover {
    transform: scale(1.1);
    transition-duration: 0.1s;
  }

  @media only screen and (max-width: 768px){
  
    color: var(--black);
    font-weight: 600;
    font-size: 18px;
    line-height: 100.52%;
    font-family: "Rubik";
  }


  }
`;

export const StyledButton2 = styled.button`
  padding: 10px;
  border-radius: 50px;
  border-color: #212529;
  border-width: 4px;
  background-color: #fad121;
  padding: 10px;
  font-family: "Rubik";
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  width: 200px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0px 4px 16px 0px #00000;
  display: inline;

  &:hover {
    transform: scale(1.1);
    transition-duration: 0.1s;
    transition: background-color .2s ease,transform .2s ease,box-shadow .2s ease;
  }


  }
`;

export const StyledButton3 = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #5CA9C8;
  background-color: #FFF;
  padding: 10px;
  font-family: "Rubik";
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  width: 200px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0 4px 1px 0 rgb(0 0 0 / 18%);

  &:hover {
    transform: scale(1.1);
    transition-duration: 0.1s;
  }


  }
`;

export const StyledButton4 = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #5CA9C8;
  background-color: #E6E6E8;
  padding: 10px;
  font-family: "Rubik";
  font-weight: 600;
  font-size: 18px;
  color: #fff!important;
  width: 200px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0 4px 1px 0 rgb(0 0 0 / 18%);

  &:hover {
    transform: scale(1.1);
    transition-duration: 0.1s;
  }


  }
`;

export const StyledRoundButton = styled.button`
  
font-size: 1.4rem;
font-family: "Rubik";
padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: papayawhip;
  padding: 10px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const ResponsiveWrapper = styled.div`
font-family: "Rubik";
display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;
export const MintWrapper = styled.div`
position: relative;
display: flex;
  }
`;


export const StyledImg = styled.img`
  width: 20px;
  height: 20px;
  bdisplay: none;
  @media (min-width: 767px) {
    width: 420px;
    height: 420px;
    background-image: url(../assets/img/noname.png);
  }

`;

export const StyledImg2 = styled.img`
  width: 170px;
  height: 170px;
  display: block;
  @media (min-width: 767px) {
    width: 420px;
    height: 420px;
    display: none;
  }

`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("Welcome to Chubby.Land");
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


   const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback("Creating your Chubby Shiba...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong. Please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, you now own a Chubby Shiba! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });

  };
  
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);






  return (
    
    <s.Screen style={{ padding: 24, height: 97, paddingtop: 120 }}>

      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ padding: 24, position: "relative", height:"100%", maxWidth: 1040, marginRight: "auto", marginLeft: "auto" }}>

          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src= "../assets/img/noname.png"/>
            <StyledImg2 alt={"example"} src= "../assets/img/bring.gif"/>

          </s.Container>
          <s.SpacerMedium/>
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{  padding: 24 }}
          >
            {Number(data.totalSupply) == 7777 ? (
              <>          
            <s.SpacerSmall />
                <s.TextTitle style={{ textAlign: "center" }}>
                SOLD-OUT!
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  BUY ON{" "}
                  <a
                    target={"_blank"}
                    href={"https://opensea.io/collection/chubbyshibanft"}
                  >
                    OPENSEA
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextDescription2 style={{ textAlign: "center" }}>
                  {feedback} 
                </s.TextDescription2>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextTitle3>
                    Chubby Land is a cute friends for NFT collectors, kawaii story lovers, and anyone who enjoys outer space, exploration, or cute characters.
                    </s.TextTitle3>
                     <s.SpacerSmall />
                    <StyledButton3
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   dispatch(connect());
                      //   getData();
                      // }}
                    ><a
                    href={"https://opensea.io/collection/chubbylandnft"} target="_blank"
                  >
                      VIEW ON OPENSEA</a>
                    </StyledButton3>
                    {/* <StyledButton4
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   dispatch(connect());
                      //   getData();
                      // }}
                    ><a
                    href={"./collection"} target="_blank"
                  >
                      VIEW COLLECTION</a>
                    </StyledButton4> */}
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}

                     <s.SpacerSmall />

                  </s.Container>
                ) : (
                  <>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription3
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          fontsize: "20px",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription3>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Shiba coming to you.." : "🐕 Adopt (Max.10)"}
                      </StyledButton>
                      </s.Container>
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.TextTitle3
                      style={{ textAlign: "center", fontSize: 20, fontFamily: "Rubik"}} >
                                              PRICE: Ξ 0.07  |  ADOPTED: {data.totalSupply}/7777</s.TextTitle3>
                    </s.Container>
                    </>   
                  
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
      </s.Container>
    </s.Screen>
  );
}

export default App;


